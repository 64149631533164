<template>
    <v-row justify="center">
        <v-dialog
            v-model="dashboardDialog"
            width="700px"
            persistent
            >
            <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="primary"
                    text
                    v-bind="attrs"
                    v-on="on"
                    small
                    >
                    <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
                    </v-btn>
            </template>
            <v-card>
                <v-card-title class="my-5">
                    {{item.title}}
                </v-card-title>
                <v-card-text class="mt-5">
                    <p style="font-size: 15px;">{{item.description}}</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="cancelDialog"
                        >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>

export default {
    props:['item'],
    data () {
        return {
            dashboardDialog: false,
        }
    },
    methods: {
        cancelDialog(){
            this.dashboardDialog = false
        },
    }
}
</script>
