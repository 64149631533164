<template>
	<div class="container">
		<v-container grid-list-md>
			<v-layout wrap>

				<v-flex md12 sm12 lg12 class="mb-5 red-text">
					<h3 class="red-text" color=red;>General Dashboard</h3>
				</v-flex>

				<v-flex md12 sm12 lg4>
					<v-card
						class="mx-auto"
						max-width="344"
						outlined
					>
						<v-list-item three-line>
							<v-list-item-content>
								<v-list-item-title class="text-h5 mb-1">
								{{single}}
								</v-list-item-title>
								<v-list-item-subtitle>Single Proprietorship</v-list-item-subtitle>
							</v-list-item-content>

							<v-list-item-avatar>
								<v-icon>mdi-account</v-icon>
							</v-list-item-avatar>
						</v-list-item>
						<v-card-actions>
							<v-progress-linear
							:indeterminate="isLoadingDashboard"
								:value="single_percent"
								color="blue-grey"
								height="15"
								>
								<template v-slot:default="{ value }">
									<strong>{{ Math.ceil(value) }}%</strong>
								</template>
							</v-progress-linear>
						</v-card-actions>
					</v-card>
				</v-flex>
				<v-flex md12 sm12 lg4>
					<v-card
						class="mx-auto"
						max-width="344"
						outlined
					>
						<v-list-item three-line>
							<v-list-item-content>
								<v-list-item-title class="text-h5 mb-1">
								{{cooperative}}
								</v-list-item-title>
								<v-list-item-subtitle>Cooperative / Hybrid</v-list-item-subtitle>
							</v-list-item-content>

							<v-list-item-avatar>
								<v-icon>mdi-account-group</v-icon>
							</v-list-item-avatar>
						</v-list-item>
						<v-card-actions>
							<v-progress-linear
							:indeterminate="isLoadingDashboard"
								:value="cooperative_percent"
								color="light-blue"
								height="15"
								>
								<template v-slot:default="{ value }">
									<strong>{{ Math.ceil(value) }}%</strong>
								</template>
							</v-progress-linear>
						</v-card-actions>
					</v-card>
				</v-flex>
				<v-flex md12 sm12 lg4>
					<v-card
						class="mx-auto"
						max-width="344"
						outlined
					>
						<v-list-item three-line>
							<v-list-item-content>
								<v-list-item-title class="text-h5 mb-1">
								{{corporate}}
								</v-list-item-title>
								<v-list-item-subtitle>Corporation</v-list-item-subtitle>
							</v-list-item-content>

							<v-list-item-avatar>
								<v-icon>mdi-office-building</v-icon>
							</v-list-item-avatar>
						</v-list-item>
						<v-card-actions>
							<v-progress-linear
							:indeterminate="isLoadingDashboard"
								:value="corporate_percent"
								color="indigo"
								height="15"
								>
								<template v-slot:default="{ value }">
									<strong>{{ Math.ceil(value) }}%</strong>
								</template>
							</v-progress-linear>
						</v-card-actions>
					</v-card>
				</v-flex>

				<!-- <v-flex>
					<v-progress-circular
					:size="100"
					:width="15"
					:value="skill"
					color="teal"
					>
					50 new existing'
					</v-progress-circular>
				</v-flex> -->

			<v-flex md12 sm12 lg12 class="my-5">
				<h3>Pera Remit Partners</h3>
				<p>as of {{currentDate}}</p>
			</v-flex>

			<v-flex md12 sm12 lg4>
				<v-card
					class="mx-auto"
					max-width="100%"
				>
					<v-card-text class=text-center>
						<v-progress-circular
					:indeterminate="isLoadingDashboard"
					:size="150"
					:width="15"
					:value="percent_new"
					color="indigo"
					>
					{{percent_new}}%
					</v-progress-circular>
					</v-card-text>
					<v-card-text class=text-center>
						<h3>{{count_new}} New</h3>
					</v-card-text>
				</v-card>
			</v-flex>
			<v-flex md12 sm12 lg4>
				<v-card
					class="mx-auto"
					max-width="100%"
				>
					<v-card-text class=text-center>
						<v-progress-circular
						:indeterminate="isLoadingDashboard"
					:size="150"
					:width="15"
					:value="percent_existing"
					color="orange"
					>
					{{percent_existing}}%
					</v-progress-circular>
					</v-card-text>
					<v-card-text class=text-center>
						<h3>{{count_existing}} Existing</h3>
					</v-card-text>
				</v-card>
			</v-flex>
			<v-flex md12 sm12 lg4>
				<v-card
					class="mx-auto"
					max-width="100%"
				>
					<v-card-text class=text-center>
						<v-progress-circular
						:indeterminate="isLoadingDashboard"
					:size="150"
					:width="15"
					:value="percent_terminated"
					color="green"
					>
					{{percent_terminated}}%
					</v-progress-circular>
					</v-card-text>
					<v-card-text class=text-center>
						<h3>{{count_terminated}} Terminated</h3>
					</v-card-text>
				</v-card>
			</v-flex>

			<v-flex md12 lg12>
				<material-card
					color="indigo"
					title="Top Performance"
					text="All Time"
				>
					<v-data-table
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
						:headers="headers"
						:items="performers"
						:calculate-widths="true"
						class="ph-nonex-datatable"
						:loading="isLoadingDashboard"  
						>
					</v-data-table>
				</material-card>
			</v-flex>

			<v-flex md12 sm12 lg6 v-if="!isLoadingDashboard">
				<h3 class="mb-5">Frequently Asked Questions (FAQ)</h3>
				<v-simple-table
					fixed-header
					style="height:1000px;overflow:auto;"
					class="box"
				>
					<template v-slot:default>
					<thead>
						<tr v-for="(item, index) in faqList" :key="index">
							<td class="text-left">
								{{index+1}}.&ensp; {{item.title}}
							</td>
							<td class="text-right">
								<v-btn
								color="primary"
								text
								small
								>
								<DashboardDialog :item="item"></DashboardDialog>
								</v-btn>
							</td>
						</tr>
					</thead>
					</template>
				</v-simple-table>
			</v-flex>
				
				<v-flex md12 sm12 lg6 v-if="!isLoadingDashboard">
					<h3 class="mb-5">System Status</h3>
					<v-simple-table
						fixed-header
						style="height:1000px;overflow:auto;"
						class="box"
					>
						<template v-slot:default>
						<thead>
							<tr v-for="(item, index) in statusList" :key="index">
								<td class="text-left">
									{{index+1}}.&ensp; {{item.title}}
								</td>
								<td class="text-right">
									<v-btn
									color="primary"
									text
									small
									>
									<DashboardDialog :item="item"></DashboardDialog>
									</v-btn>
								</td>
							</tr>
						</thead>
						</template>
					</v-simple-table>

				</v-flex>

				<v-flex md12 lg12 v-if="!isLoadingDashboard">
					<v-data-table
						:headers="head"
						:items="acronymList"
						:items-per-page="15"
						item-key="name"
						class="elevation-1 box"
						:footer-props="{
						showFirstLastPage: true,
						firstIcon: 'mdi-arrow-collapse-left',
						lastIcon: 'mdi-arrow-collapse-right',
						prevIcon: 'mdi-minus',
						nextIcon: 'mdi-plus'
						}"
						:search = "search"
					>
							<template v-slot:top>
								<v-toolbar flat color="white">
									<v-layout>
										<h3 class="mx-3 my-3">SAM System Acronyms</h3>
										<v-spacer></v-spacer>
										<v-flex md4 class="search">
											<v-text-field
												v-model="search"
												placeholder="Search"
												label="Search"
												single-line
												hide-details
											></v-text-field> 
										</v-flex>
									</v-layout>
								</v-toolbar>
							</template>
					</v-data-table>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>

import Card from '@/components/material/Card.vue';
import { mapGetters } from 'vuex';


import DashboardDialog from '../components/modules/salesProspecting/DashboardDialog.vue';
export default {
	computed: {
		...mapGetters({
			isLoadingDashboard: 'app/isLoadingDashboard'
		})
	},
	components: {
		'material-card': Card,
		DashboardDialog: DashboardDialog,
	},
    data () {
        return {
			// sortBy: [{ key: 'no_of_sp', order: 'desc' }],
			// sortBy: 'no_of_sp',
			sortBy: 'no_of_sp',
			sortDesc: true,
			skill: 50,
            faqList: [],
            acronymList: [],
            statusList: [],
			performers:[],
			search:'',
			head: [
            {
                text: 'Acronym',
                align: 'start',
                value: 'title',
            },
            { text: 'Meaning', value: 'description' },
            ],
			headers: [
				
				{
					text: 'Cluster Head',
					sortable: false,
					value: 'cluster_head_name'
				},
				{
					text: 'No of SP',
					sortable: false,
					value: 'no_of_sp'
				},
				{
					text: 'Approve',
					sortable: false,
					value: 'approved'
				},
				{
					text: 'Pending',
					sortable: false,
					value: 'pending'
				},

			],
			single: '',
			single_percent: '',
			cooperative: '',
			cooperative_percent: '',
			corporate: '',
			corporate_percent: '',
			count_new: '',
			percent_new: '',
			count_existing: '',
			percent_existing: '',
			count_terminated: '',
			percent_terminated: '',
			currentDate:''
        }
    },
    async mounted () {
		const res = await this.$store.dispatch('salesProspecting/doGetDashboardData')
		this.assigndata(res.data.result)
		const resP = await this.$store.dispatch('salesProspecting/doGetDashboard')
		this.assignDashboardDetails(resP.data.result)
		console.log(resP.data.result)

		const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
		const d = new Date();
		this.currentDate = month[d.getMonth()];
    },
	methods:{
		assigndata(data){
			this.faqList = data.dashboard_faq
			this.statusList = data.dashboard_status
			this.acronymList = data.dashboard_acronym
		},
		assignDashboardDetails(data){
			this.performers = data.performance
			this.single = data.sp_type.count_single_proprietor
			this.single_percent = data.sp_type.percent_single_proprietor
			this.cooperative = data.sp_type.count_cooperative_hybrid
			this.cooperative_percent = data.sp_type.percent_cooperative_hybrid
			this.corporate = data.sp_type.count_corporation
			this.corporate_percent = data.sp_type.percent_corporation
			this.count_new = data.prp.count_new
			this.percent_new = data.prp.percent_new
			this.count_existing = data.prp.count_existing
			this.percent_existing = data.prp.percent_existing
			this.count_terminated = data.prp.count_terminated
			this.percent_terminated = data.prp.percent_terminated
		},
		showDetails(item){
			console.log(item)
		}
	}
}
</script>

<style scoped>
.box {
	border: 1px solid;
}
</style>